import React, { useContext } from 'react';
import {
  Modal,
  ModalBody,
  ModalFooter,
  ModalHeader,
  MODAL_SIZES,
  Steps,
  Step,
} from '@user-interviews/ui-design-system';

import { TrackedButton } from 'common/analytics';
import { AccountCollaboratorsContext } from 'hoc/with_account_collaborators_context';
import { useAccountLookbackEnabled } from 'hooks/use_account_lookback_enabled';
import { OauthButton } from 'components/oauth_button';
import { oauthActions, oauthUserTypes } from 'common/routing';

import { trackingEvents } from 'lib/analytics';
import { providerNames } from 'lib/generated_constants/integrations';
import * as propTypes from 'lib/prop_types';

// TODO This is an ugly hack to get around any sort of actual authentication with the Lookback app.
// Ideally this would be OAuth like the others..
export function LookbackButton({ onSubmit, updateLookbackEnabledInternally }) {
  const { updateLookbackEnabledOnAccount } = useAccountLookbackEnabled();
  // The project owner is the source of truth for whether Lookback is enabled so we need to refresh
  // that in addition to updating the account.
  const { reload } = useContext(AccountCollaboratorsContext);

  const handleSubmit = async (e) => {
    e.preventDefault();

    await Promise.all([
      (updateLookbackEnabledInternally && updateLookbackEnabledOnAccount(true)),
      reload(),
      onSubmit(e),
    ]);
  };

  return (
    // TODO UIDS-697 Add aria attributes to Form so that we can use it here.
    <form aria-label="Connect Lookback" onSubmit={handleSubmit}>
      <TrackedButton
        event={trackingEvents.CONNECT_WITH_INTEGRATION_CLICKED}
        eventData={{ provider: providerNames.LOOKBACK }}
        type="submit"
      >
        Connect
      </TrackedButton>
    </form>
  );
}

LookbackButton.propTypes = {
  updateLookbackEnabledInternally: propTypes.bool,
  onSubmit: propTypes.func,
};

LookbackButton.defaultProps = {
  onSubmit: undefined,
  updateLookbackEnabledInternally: true,
};

function ConnectModal({
  provider,
  onClose,
}) {
  const { name: providerName, value: providerValue } = provider ?? {};

  return (
    <Modal
      aria={{
        describedby: `connect-${providerValue}-description`,
        labelledby: `connect-${providerValue}-name`,
      }}
      className="ConnectModal"
      isOpen={!!provider}
      size={MODAL_SIZES.MEDIUM}
    >
      <ModalHeader
        title={`Connect ${providerName}?`}
        titleId={`connect-${providerName}-name`}
        onRequestClose={onClose}
      />
      <ModalBody>
        <Steps id={`connect-${providerValue}-description`}>
          <Step
            circleText="1"
            text={(
              <>
                <b>Connect and sign in</b> with your {providerName} account.
              </>
            )}
          />
          <Step
            circleText="2"
            text={(
              <>
                <b>Check</b> that the {providerName} project we've selected on your
                User Interviews project is the right one.
              </>
            )}
          />
          <Step
            circleText="3"
            text={(
              <>
                <b>Automate</b> progress tracking and participant communication!
              </>
            )}
          />
        </Steps>
      </ModalBody>
      <ModalFooter onRequestClose={onClose}>

        {providerValue === providerNames.LOOKBACK &&
          (
            <LookbackButton onSubmit={onClose} />
          )}

        {providerValue && providerValue !== providerNames.LOOKBACK &&
          (
            // TODO UIDS-697 Add aria attributes to Form so we can remove this div here and put aria
            // label on OauthButton.
            <div aria-label={`Connect ${providerName}`} role="form">
              <OauthButton
                action={oauthActions.CONNECT}
                provider={providerValue}
                trackingEvent={trackingEvents.CONNECT_WITH_INTEGRATION_CLICKED}
                trackingEventData={{ provider: providerValue }}
                userType={oauthUserTypes.ACCOUNT}
              >
                Connect
              </OauthButton>
            </div>
          )}

      </ModalFooter>
    </Modal>
  );
}

ConnectModal.propTypes = {
  provider: propTypes.shape({
    name: propTypes.string,
    value: propTypes.string,
  }),
  onClose: propTypes.func,
};

export default ConnectModal;
